import { LOGIN_ADMIN,LOGOUT_ADMIN, ADMIN_LOGIN_ERROR } from "../Actions/ActionTypes";

const InitialState = {
    token: null,
    authenticated: false,
    authenticating: false,
    error: '',
    showError: false,
}

export default function MerchantAuthReducer(state = InitialState, action) {
    switch(action.type){
        case LOGIN_ADMIN:
            return { ...state, token: action.payload, authenticated: true, error: '', showError: false };
        case LOGOUT_ADMIN:
            return { ...state, token: null, authenticated: false, error: '', showError: false };
        case ADMIN_LOGIN_ERROR:
            return {...state, error: "Email-id or password is incorrect", showError: true}
        default :
            return state
    }
}
