import React, {createRef} from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import {
    Grid, Card, Typography, FormControl, Snackbar, CircularProgress,
    Button, Collapse, FormControlLabel, Checkbox, Fade
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import './login.css';
import Text_Field from "../../Util/TextView"
import { LoginAdmin, IsLoggedIn } from "../../Redux/Actions/AdminAuthActions"
import Footer from "../../containers/DefaultLayout/Footer";

class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            Shake: "",
            isLoading: false,
            showPasswordError: false,
            Email: "",
            Password: "",
            errors: { Email: "", Password: "" },
            showEmailError: false,
            showPasswordError: false,
        }
        this.emailRef = createRef();
        this.passwordRef = createRef();
        this.loginButtonRef = createRef();
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.validate = this.validate.bind(this);
        this.errorFor = this.errorFor.bind(this);
    }

    componentDidMount() {
        this.props.IsLoggedIn()
    }

    handleClose(event, reason) {
        if (reason === 'clickaway')
            return;
        this.setState(prevState => ({ ...prevState, open: false }))
    }

    handleChange(event) {
        this.setState({ ...this.state, [event.target.name]: event.target.value })
    }

    handleKeyPress(event) {
        if (event.key == "Enter") {
            if (event.target.name == "Email") {
                this.passwordRef.current.focus();
            } else if (event.target.name == "Password") {
                this.loginButtonRef.current.click();
            }
        }
    }

    handleLogin() {
        let isValid = this.validate();
        if (isValid) {
        const loginDetails = {
            Email: this.state.Email,
            Password: this.state.Password
        }
        this.props.LoginAdmin(loginDetails);
        }
    }

    validate() {
        let errors = {};
        let isValid = false;
        let emailError = false;
        let passwordError = false;
        if (this.state.Email == "") {
            errors.Email = "Please enter your email address";
        }
        if (this.state.Password == "") {
            errors.Password = "Please enter your password";
        }
        let errorsList = Object.keys(errors);
        if (errorsList.length == 0) {
            isValid = true
        } else {
            for (let i = 0; i < errorsList.length; i++) {
                if (errorsList[i] == "Email") {
                    emailError = true;
                } else if (errorsList[i] == "Password") {
                    passwordError = true;
                }
            }
        }
        this.setState(prevState => {
            return {
                ...prevState, showEmailError: emailError, showPasswordError: passwordError, errors: errors
            }
        });
        return isValid;
    }

    errorFor(feild) {
        return ""
    }


    render() {
        return (
            <React.Fragment>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} TransitionComponent={Fade} open={this.state.open} autoHideDuration={12000} onClose={this.handleClose}>
                    <Alert elevation={6} variant="filled" onClose={this.handleClose} severity="error"> Something Went wrong Please try Again. </Alert>
                </Snackbar>
                {!this.props.Authenticated ?
                    <div id="containerbar" className="authenticate-bg">
                        <Collapse in={true} className="login-outer">
                            <Grid container className="LoginContainer" spacing={0} justify="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={8} xl={8} className={this.state.Shake}>
                                    <Card>
                                        <div>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <div className="login-logo"> <img src='/logo.png' alt="" /></div>
                                                </Grid>

                                            </Grid>
                                        </div>

                                        <div className="box-login">
                                            <Grid container>
                                                <Grid item xs={12} width="100%">
                                                    <div className="form-group">
                                                        <FormControl fullWidth >

                                                            <Text_Field
                                                                name='Email'
                                                                id="Email"
                                                                inputRef={this.emailRef}
                                                                autoFocus={true}
                                                                inputProps={{ maxLength: 100 }}
                                                                helperText={this.errorFor("Email")}
                                                                margin='normal'
                                                                onKeyPress={this.handleKeyPress}
                                                                onChange={(e) => this.handleChange(e)}
                                                                label="Email" variant="standard" />
                                                            <FormHelperText id="Email" className="text-danger">
                                                                {this.state.errors.Email}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}  >
                                                    <div className="form-group">
                                                        <FormControl fullWidth>
                                                            <Text_Field inputProps={{ maxLength: 50 }}
                                                                name='Password'
                                                                id="Password"
                                                                inputRef={this.passwordRef}
                                                                helperText={this.errorFor("Password")}
                                                                type="Password"
                                                                onChange={(e) => this.handleChange(e)}
                                                                onKeyPress={this.handleKeyPress}
                                                                label="Password" variant="standard" />
                                                            <FormHelperText id="Password" className="text-danger">
                                                                {this.state.errors.Password}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                {/* <Grid item xs={12} width="100%">
                                                    <div className="form-group">
                                                        <FormControl fullWidth >
                                                                <FormControlLabel value="end" control={<Checkbox color="primary" />} label="Remember me" labelPlacement="end" />
                                                        </FormControl>
                                                    </div>
                                                </Grid> */}
                                                {this.showPasswordError ?
                                                    <Grid item xs={12} width="100%">
                                                        <div className="form-group">
                                                            <FormControl fullWidth >
                                                                <Alert severity="error" variant="standard" >Either Email or password is incorrect</Alert>
                                                            </FormControl>
                                                        </div>
                                                    </Grid> : null}

                                                <Grid className="mt-3" item xs={12} width="100%">
                                                    <div className="form-group">
                                                        <FormControl fullWidth >
                                                            <Button
                                                                buttonRef={this.loginButtonRef}
                                                                disabled={this.isLoading}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={this.handleLogin}>
                                                                {this.isLoading ? <CircularProgress size={24} style={{ color: '#fff' }} /> : 'Sign In'}
                                                            </Button>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <div className={"alert alert-danger p-2 " + (this.props.showError ? "d-block" : "d-none")} role="alert">
                                        {this.props.error}
                                    </div>
                                </Grid>
                            </Grid>
                        </Collapse>
                        {/* <Footer /> */}
                    </div> : <Redirect to="/dashboard/info" />}
            </React.Fragment>
        );
    }
}

function mapStateToProps(store) {
    return { Authenticated: store.AdminAuth.authenticated, showError: store.AdminAuth.showError, error: store.AdminAuth.error }
}

const mapActionToProps = {
    LoginAdmin,
    IsLoggedIn
}

export default connect(mapStateToProps, mapActionToProps)(AdminLogin)