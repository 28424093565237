import React, { createRef } from "react";
import { connect } from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import SearchBar from "material-ui-search-bar";

import { GetUpcomingEvents } from "../../Redux/Actions/AdminDashboardActions";
import "./AdminUpcomingEvents.css";

class AdminUpcomingEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leagueNameSorted: false,
            homeTeamSorted: false,
            awayTeamSorted: false,
            timeSorted: false,
            sportNameSorted: false,
            leagueNameDirection: 'asc',
            homeTeamDirection: 'asc',
            awayTeamDirection: 'asc',
            timeDirection: 'asc',
            sportNameDirection: 'asc',
            leagueNameSortType: 'default',
            homeTeamSortType: 'default',
            awayTeamSortType: 'default',
            timeSortType: 'default',
            sportNameSortType: 'default',
            orderBy: '',
            sortType: "default",
            currentPage: 0,
            pageSize: 10,
            startIndex: 0,
            endIndex: 1,
            searchKeyword: '',
            applyFilter: false,
            totalItems: 0,
            toggleFlag: false,
        }
        this.searchBarRef = createRef();
        this.handleSearchKeywordChange = this.handleSearchKeywordChange.bind(this);
        this.handleCancelSearch = this.handleCancelSearch.bind(this);
        this.handleSearchRequest = this.handleSearchRequest.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.setSortingParameters = this.setSortingParameters.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.sortClients = this.sortClients.bind(this);
        this.setStartEndIndex = this.setStartEndIndex.bind(this);
        this.GetTableBody = this.GetTableBody.bind(this);
        this.restorePagingInfo = this.restorePagingInfo.bind(this);
    }

    componentDidMount() {
        const state = this.props.location.state;
        if (state === undefined) {
            this.props.GetUpcomingEvents().then(res => this.setStartEndIndex());
        } else {
            this.props.GetUpcomingEvents().then(this.restorePagingInfo());
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.applyFilter !== this.state.applyFilter && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
        if (prevState.searchKeyword !== this.state.searchKeyword && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
        if (prevProps.DeleteUserToggle !== this.props.DeleteUserToggle) {
            this.props.FetchAllUsers();
        }
    }

    handleSearchKeywordChange(newKeyword) {
        let applyFilter = true;
        if (newKeyword === "") {
            applyFilter = false;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: newKeyword,
                applyFilter,
            }
        });
    }

    handleCancelSearch() {
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: '',
                applyFilter: false,
            }
        });
    }

    handleSearchRequest() {
        this.setState(prevState => {
            return {
                ...prevState,
                applyFilter: true,
            }
        });
    }

    handleKeyPress(event) {
        if (event.key == "Escape") {
            if (event.target.name == "searchBar") {
                this.handleCancelSearch();
            }

        }
    }

    restorePagingInfo() {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword } = this.props.location.state.pagingInfo;
        this.setState(prevState => {
            return {
                ...prevState,
                currentPage,
                startIndex,
                endIndex,
                totalItems,
                applyFilter,
                searchKeyword,
                toggleFlag: !this.state.toggleFlag,
            }
        });
    }

    setSortingParameters(feild) {
        let sortType;
        let type = "";
        let direction = '';
        let leagueNameSorted = false;
        let homeTeamSorted = false;
        let awayTeamSorted = false;
        let timeSorted = false;
        let sportNameSorted = false;
        let {
            leagueNameDirection,
            homeTeamDirection,
            awayTeamDirection,
            timeDirection,
            sportNameDirection,
            leagueNameSortType,
            homeTeamSortType,
            awayTeamSortType,
            timeSortType,
            sportNameSortType,
        } = this.state;

        if (feild === "leagueName") {
            sortType = this.state.leagueNameSortType;

        } else if (feild === "homeTeam") {
            sortType = this.state.homeTeamSortType;
        } else if (feild === "awayTeam") {
            sortType = this.state.awayTeamSortType;
        } else if (feild === "time") {
            sortType = this.state.timeSortType;
        } else if (feild === "sportName") {
            sortType = this.state.sportNameSortType;
        }

        if (sortType === "default") {
            type = "ascending";
            direction = 'asc';
        } else if (sortType === "ascending") {
            type = "descending";
            direction = 'desc';
        } else if (sortType === "descending") {
            type = "default";
        }

        if (type === "ascending" || type === "descending") {
            if (feild === "leagueName") {
                leagueNameSorted = true;
                leagueNameDirection = direction;
                leagueNameSortType = type;
            } else if (feild === "homeTeam") {
                homeTeamSorted = true;
                homeTeamDirection = direction;
                homeTeamSortType = type;
            } else if (feild === "awayTeam") {
                awayTeamSorted = true;
                awayTeamDirection = direction;
                awayTeamSortType = type;
            } else if (feild === "time") {
                timeSorted = true;
                timeDirection = direction;
                timeSortType = type;
            } else if (feild === "sportName") {
                sportNameSorted = true;
                sportNameDirection = direction;
                sportNameSortType = type;
            }
        } else {
            if (feild === "leagueName") {
                leagueNameSorted = false;
                leagueNameDirection = 'asc';
                leagueNameSortType = "default";
            } else if (feild === "homeTeam") {
                homeTeamSorted = false;
                homeTeamDirection = "asc";
                homeTeamSortType = "default";
            } else if (feild === "awayTeam") {
                awayTeamSorted = false;
                awayTeamDirection = "asc";
                awayTeamSortType = "default";
            } else if (feild === "time") {
                timeSorted = false;
                timeDirection = "asc";
                timeSortType = "default";
            }  else if (feild === "sportName") {
                sportNameSorted = false;
                sportNameDirection = "asc";
                sportNameSortType = "default";
            }
        }
        this.setState(prevState => {
            return {
                ...prevState,
                orderBy: feild,
                sortType: type,
                leagueNameDirection,
                homeTeamDirection,
                awayTeamDirection,
                timeDirection,
                sportNameDirection,
                leagueNameSortType,
                homeTeamSortType,
                awayTeamSortType,
                timeSortType,
                sportNameSortType,
                leagueNameSorted,
                homeTeamSorted,
                awayTeamSorted,
                timeSorted,
                sportNameSorted,
            }
        });
    }

    applyFilter() {
        let clients = this.props.Clients;
        const searchKeyword = this.state.searchKeyword;
        const result = clients.filter(client => {
            if (client["leagueName"] !== null) {
                if (client["leagueName"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["homeTeam"] !== null) {
                if (client["homeTeam"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["awayTeam"] !== null) {
                if (client["awayTeam"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["sportName"] !== null) {
                if (client["sportName"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            else{
                return false;
            }
        });
        return result;
    }

    sortClients(a, b) {
        const sortType = this.state.sortType;
        const orderBy = this.state.orderBy;
        let firstElement =  a[orderBy] == null ? "" : a[orderBy].toLowerCase();;
        let secondElement =  b[orderBy] == null ? "" : b[orderBy].toLowerCase();;

        if (sortType === "default") {
            return a;
        } else if (sortType === "ascending") {
            if (firstElement > secondElement) {
                return 1;
            } else if (firstElement <= secondElement) {
                return -1;
            }

        } else if (sortType === "descending") {
            if (firstElement >= secondElement) {
                return -1;
            } else if (firstElement < secondElement) {
                return 1;
            }
        }

    }

    setStartEndIndex(event, newPage = 0) {
        const { pageSize, applyFilter } = this.state;
        let totalItems;
        if (applyFilter) {
            totalItems = this.applyFilter();
            totalItems = totalItems.length;
        } else {
            totalItems = this.props.Clients.length;
        }
        const currentPage = newPage;
        let startIndex = (currentPage) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        this.setState({
            ...this.state,
            startIndex: startIndex,
            endIndex: endIndex,
            currentPage: newPage,
            totalItems
        });
    }

    GetTableBody() {
        const { applyFilter } = this.state;
        let clients;
        if (applyFilter) {
            clients = this.applyFilter();
        } else {

            clients = this.props.Clients;
        }
        let markup = [...clients].sort(this.sortClients).map((row) => (
            <TableRow key={row.userId}>
                <TableCell component="th" scope="row">{row.leagueName}</TableCell>
                <TableCell align="left">{row.homeTeam}</TableCell>
                <TableCell align="left">{row.awayTeam}</TableCell>
                <TableCell align="left">{row.sportName}</TableCell>
                <TableCell align="left">{row.haveToWinOdds ? "To Win" : "Handicap"}</TableCell>
                <TableCell align="left">{row.time}</TableCell>
                <TableCell align="left">{row.homeWinOdds}</TableCell>
                <TableCell align="left">{row.drawOdds}</TableCell>
                <TableCell align="left">{row.awayWinOdds}</TableCell>
            </TableRow>
        ));
        return markup.slice(this.state.startIndex, this.state.endIndex + 1);
    }
    render() {
        const tableBodyMarkup = this.GetTableBody();
        return (

            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="h3" />
                    <div className="mb-15 text-center" style={{color: "red"}}>
                        Note: The prematch odds will appear 5 minutes before the game starts.
                    </div>
                    <SearchBar
                        value={this.state.searchKeyword}
                        cancelOnEscape={true}
                        onChange={(newKeyword) => this.handleSearchKeywordChange(newKeyword)}
                        onRequestSearch={() => this.handleSearchRequest()}
                        onCancelSearch={() => this.handleCancelSearch()}
                        inputRef={this.searchBarRef}
                        name="searchBar"
                        onKeyDown={this.handleKeyPress}
                    />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.leagueNameSorted}
                                            direction={this.state.leagueNameDirection}
                                            onClick={() => this.setSortingParameters("leagueName")}
                                        >
                                            League Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.homeTeamSorted}
                                            direction={this.state.homeTeamDirection}
                                            onClick={() => this.setSortingParameters("homeTeam")}
                                        >
                                            Home Team
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.awayTeamSorted}
                                            direction={this.state.awayTeamDirection}
                                            onClick={() => this.setSortingParameters("awayTeam")}
                                        >
                                           Away Team
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                    <TableSortLabel
                                            active={this.state.timeSorted}
                                            direction={this.state.sportNameDirection}
                                            onClick={() => this.setSortingParameters("sportName")}
                                        >
                                        Sport
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        Odd Type
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.timeSorted}
                                            direction={this.state.timeDirection}
                                            onClick={() => this.setSortingParameters("time")}
                                        >
                                        Melbourne Time
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        Home Odds
                                    </TableCell>
                                    <TableCell align="left">
                                        Draw
                                    </TableCell>
                                    <TableCell align="left">Away Odds</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableBodyMarkup}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={this.state.totalItems}
                        rowsPerPage={this.state.pageSize}
                        page={this.state.currentPage}
                        onChangePage={this.setStartEndIndex}
                    />
                </Container>

            </React.Fragment>
        )

    }

}

function mapStateToProps(store) {
    return {
        Clients: store.Admin.upcomingEvents,
        
    }
}

const mapActionsToProps = {
    GetUpcomingEvents
}

export default connect(mapStateToProps, mapActionsToProps)(AdminUpcomingEvents)