import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core'
import {
    EditTwoTone, SearchTwoTone, DeleteTwoTone, CallTwoTone, MailTwoTone, MapTwoTone, AssignmentReturned, ContactsTwoTone,
    ViewHeadlineTwoTone, PersonAddTwoTone, StarTwoTone, History
} from '@material-ui/icons';

export default class Text_Field extends React.Component {

    constructor(props) {
        super(props);
        this.shouldDisplayError = this.shouldDisplayError.bind(this);
    }

    shouldDisplayError() {
        return this.props.showError && this.props.errorText !== "";
    }

    render() {
        return (
            <TextField
                autoFocus={this.props.autoFocus}
                inputRef={this.props.inputRef}
                onKeyPress={this.props.onKeyPress}
                type={this.props.type || "text"}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={this.props.value}
                margin={this.props.margin}
                helperText={this.props.helperText}
                error={this.props.helperText.length !== 0}
                onChange={this.props.onChange}
                label={this.props.label}
                variant={this.props.variant}
                inputProps={this.props.inputProps}
            ></TextField>
        );
    }
}

TextField.propTypes = {
    // showError: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

