import React,{useContext} from 'react';   
import { makeStyles } from '@material-ui/core/styles'; 
import { IconButton, Button, Grid, Avatar ,Popover,Typography,Menu,MenuItem,ListItem,ListItemIcon,List,ListItemText} from '@material-ui/core'; 
import {PersonTwoTone,LockTwoTone} from '@material-ui/icons'; 
import MenuIcon from '@material-ui/icons/Menu'; 
import context from '../../store/context'
import {useDispatch} from "react-redux";
import { LogoutAdmin } from "../../Redux/Actions/AdminAuthActions";
import { LOGOUT_ADMIN } from "../../Redux/Actions/ActionTypes";


const useStyles = makeStyles((theme) => ({
    root: {flexGrow: 1}, 
    padding8:{padding:8},
    textOrigional:{textTransform:'initial'},
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize:12,
        marginLeft:5,
        background:'#eef1ff',
        color:'#5c77ff'
      },
  }));

export default function Header(props) {  
    const classes = useStyles();
    const {globalState,globalDispatch} = useContext(context); 
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const dispatch = useDispatch();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <div className={"topbar "+ (props.toggleProp?"":"menuToggle")}> 
        <Grid container>
            {/* <Grid item  xs={4} sm={4} md={4} xl={4} >
                <IconButton  className={classes.padding8} onClick={props.onClick} > <MenuIcon /></IconButton>
                </Grid> */}

                <Grid item xs={7} sm={7} md={6} xl={6} >
                    <img src="/logo2.png"/>
                </Grid>

            <Grid item   xs={5} sm={5} md={6} xl={6} >
                <Grid container justify="flex-end" alignItems="center">
                   
                    <Button aria-describedby={id} onClick={handleClick} className={classes.textOrigional}> Admin <Avatar className={classes.small} size='small'><PersonTwoTone/></Avatar></Button>
                    <Popover 
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                    > 

                    <List component="nav" aria-labelledby="nested-list-subheader"   >      
                                <ListItem button onClick={() => { localStorage.removeItem('Auth_Token'); dispatch({ type: LOGOUT_ADMIN }) }}>
                            <ListItemIcon> <LockTwoTone /> </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>  
                    </Popover> 
                </Grid> 
            </Grid>
        </Grid> 
        </div>  
         
    );
}