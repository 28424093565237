import {useReducer} from  'react'

const reducer =(state,action)=>{
    switch(action.type)
    {
        case "Login":
            { 
                localStorage.setItem("Auth_Token", action.state);
                localStorage.setItem("isLoggedIn", true);
                return {isLoggedIn:true,Auth_Token:action.state}
            }
        case "Logout":
            { 
                localStorage.setItem("isLoggedIn", false);
                return {isLoggedIn:false}
            }
        case "Registration":
            {   
                localStorage.setItem("Auth_Token", action.state);
                localStorage.setItem("isLoggedIn", true); 
                return {isLoggedIn:true}
            }
        default :
            return state;
    }
}

const useGlobalState =()=>{

    let localLoggedIn = localStorage.getItem("isLoggedIn");
    let LoggedIN = false;
    if(!(localLoggedIn==undefined||localLoggedIn==null))
    {
        LoggedIN = localLoggedIn=="false"?false:true;
    }
    
    const [globalState,globalDispatch]=useReducer(reducer,{isLoggedIn:LoggedIN,
                                                           Auth_Token:localStorage.getItem("Auth_Token")});

    return {globalState,globalDispatch};
};

export default useGlobalState;