import React, { Suspense } from 'react';  
import '../../assets/css/DefaultLayout.css'
import '../../assets/css/Menu.css'  
import LinearProgress from '@material-ui/core/LinearProgress';
  
import routes from '../../routes';
import { Route, Switch } from 'react-router-dom';

const loading = () => <LinearProgress className="LinearProgress"></LinearProgress>;
export default function RightBar(props) { 
  
    return (  
        <div   className={"rightbar " + (props.toggleProp?"":"menuToggle")}> 
          
              <div className="rightContainer">
              <Suspense fallback={loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component pageAttr={route.name} {...props} />
                        )} />
                    ) : (null);
                  })} 
                </Switch>
               </Suspense>
            </div> 
        </div> 
    );
}