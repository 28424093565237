import React from 'react';
import { connect } from "react-redux";
import { ResetPassword } from "../../Redux/Actions/AdminDashboardActions";
class ForgetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleChange: true,
        }
    }

    componentDidMount() {
        let email = this.props.match.params.email
        let token = this.props.match.params.forgetPasswordToken;
        if (email != undefined && token != undefined) {
            this.props.ResetPassword(email, token);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.request != this.props.request) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    toggleChange: !this.state.toggleChange,
                }
            })
        }
    }


    render() {
        let text = "";
        let image = "";
        if (this.props.request){
        if (this.props.status){
            text = "Your password has been sent to your registered email address.";
            image = "/Sucess.png";
        }
        else {
            text = "Token expired, Please generate a new reset password request.";
            image = "/Failure.png";
        }
    }

        return (
            <React.Fragment>

                <div className="page-content container">
                    <div className="container px-0">
                        <div className="row mt-4">
                            <div className="col-12 col-lg-10 offset-lg-1 bg-box">
                                <hr className="row brc-default-l1 mx-n1 mb-4" />
                                <div className="text-center">
                                <img src={image} width="250" />
                                    <h1>{text}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(store) {
    return {
        status: store.Admin.resetPasswordStatus,
        request: store.Admin.resetPasswordRequest
    }
}

const mapActionsToProps = {
    ResetPassword
}

export default connect(mapStateToProps, mapActionsToProps)(ForgetPassword)