import React, { createRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";

import {
  GetPreMatchOddsPreference,
  SetPreMatchOddsPreference,
} from "../../Redux/Actions/AdminDashboardActions";

class AdminSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      odds: 0,
      success: false,
      failure: false,
      showOddsError: false,
      errors: {
        odds: "",
      },
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.doValidation = this.doValidation.bind(this);
  }

  componentDidMount() {
    this.props.GetPreMatchOddsPreference();
    this.setState({ ...this.state, odds: this.props.settings.odds })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.settings.odds !== this.props.settings.odds) {
      this.setState({ ...this.state, odds: this.props.settings.odds });
    }

    if (prevProps.settings.success !== this.props.settings.success) {
      this.setState({ ...this.state, odds: this.props.settings.success });
    }
  }

  handleSave() {
    const result = this.doValidation();
    const { valid } = result;

    if (valid) {
      const odds = {
        PreMatchOddsPreference: parseFloat(this.state.odds),
      };

      this.props
        .SetPreMatchOddsPreference(odds)
        .then(() => this.setState({ ...this.state, success: true, showOddsError: false, errors: {...this.state.errors, odds: ""} }))
        .catch(() => this.setState({ ...this.state, failure: true, showOddsError: false, errors: {...this.state.errors, odds: ""} }));
    } else {
      const { showOddsError, errors } = result;

      this.setState({ ...this.state, showOddsError, errors });
    }
  }

  handleChange(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.value });
  }

  handleClose(event) {
    this.setState({ ...this.state, success: false, failure: false });
  }

  doValidation() {
    let errors = {};
    let showOddsError = false;
    let valid = false;
    if (this.state.odds == "") {
      errors.odds = "Please enter a value";
      showOddsError = true;
    } else if (this.state.odds.toString().search(/\./) != -1){
        if(this.state.odds.toString().split('.')[1].length > 2){
            errors.odds = "Please enter a value with decimal points upto 2 only";
            showOddsError = true;
        }
    }else if (
      parseFloat(this.state.odds) > 99.99 ||
      parseFloat(this.state.odds) < 1.01
    ) {
      errors.odds = "Please enter a value in between 1.01 and 99.99";
      showOddsError = true;
    }

    let errorTypes = Object.keys(errors);
    if (errorTypes.length == 0) {
      valid = true;
    }

    const result = {
      errors,
      valid,
      showOddsError,
    };

    return result;
  }
  render() {
    const isEditing = this.props.location.state == undefined ? false : true;
    return (
      <Paper className="p-3">
        {/*start of container grid*/}
        <Grid container spacing={2} alignContent="center">
          {/*Start of first name*/}
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              id="odds"
              label="Pre match odd preference"
              placeholder="Pre match odd preference"
              inputRef={this.FirstNameRef}
              type="number"
              value={this.state.odds}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              fullWidth
              error={this.state.showOddsError}
            />
            <FormHelperText id="FirstName" className="text-danger">
              {this.state.errors.odds}
            </FormHelperText>
          </Grid>
          {/*End of first name*/}

          <Grid item xs={12} sm={6} md={6}>
            <Tooltip
              title={this.props.location.state !== undefined ? "Edit" : "Save"}
            >
              <Button
                variant="contained"
                color="primary"
                className={"m-2 no-margin"}
                buttonRef={this.SaveButtonRef}
                onClick={this.handleSave}
                startIcon={<SaveIcon fontSize="large" />}
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
        {/*End of container grid*/}
        <Snackbar
          open={this.state.success}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="success"
          >
            Pre match odds preference is updated.
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.failure}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="error"
          >
            Pre match odds preference update failed.
          </MuiAlert>
        </Snackbar>
      </Paper>
    );
  }
}

function mapStateToProps(store) {
  return {
    settings: store.Admin.settings,
  };
}

const mapActionToProps = {
  GetPreMatchOddsPreference,
  SetPreMatchOddsPreference,
};

export default connect(mapStateToProps, mapActionToProps)(AdminSettings);
