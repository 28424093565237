import React, { createRef } from "react";
import { connect } from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import SearchBar from "material-ui-search-bar";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

import { FetchAllUsers, ToggleActiveState } from "../../Redux/Actions/AdminDashboardActions";
import "./AdminUsersDetails.css";
import { First } from "react-bootstrap/PageItem";

class AdminUsersDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClientUpdated: false,
            firstNameSorted: false,
            middleNameSorted: false,
            lastNameSorted: false,
            countrySorted: false,
            emailSorted: false,
            firstNameDirection: 'asc',
            middleNameDirection: 'asc',
            lastNameDirection: 'asc',
            countryDirection: 'asc',
            emailDirection: 'asc',
            orderBy: '',
            firstNameSortType: 'default',
            middleNameSortType: 'default',
            lastNameSortType: 'default',
            countrySortType: 'default',
            emailSortType: 'default',
            sortType: "default",
            currentPage: 0,
            pageSize: 10,
            startIndex: 0,
            endIndex: 1,
            searchKeyword: '',
            applyFilter: false,
            totalItems: 0,
            toggleFlag: false,
            showTogglePopup: false,
            userIdForToggle: '',
        }
        this.searchBarRef = createRef();
        this.handleEdit = this.handleEdit.bind(this);
        this.handleUpdateClients = this.handleUpdateClients.bind(this);
        this.handleSearchKeywordChange = this.handleSearchKeywordChange.bind(this);
        this.handleCancelSearch = this.handleCancelSearch.bind(this);
        this.handleSearchRequest = this.handleSearchRequest.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.setSortingParameters = this.setSortingParameters.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.sortClients = this.sortClients.bind(this);
        this.setStartEndIndex = this.setStartEndIndex.bind(this);
        this.GetTableBody = this.GetTableBody.bind(this);
        this.restorePagingInfo = this.restorePagingInfo.bind(this);
        this.handleAddNewUser = this.handleAddNewUser.bind(this);
        this.handleToggleActiveState = this.handleToggleActiveState.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        const state = this.props.location.state;
        if (state === undefined) {
            this.props.FetchAllUsers().then(res => this.setStartEndIndex());
        } else {
            this.props.FetchAllUsers().then(this.restorePagingInfo());
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.applyFilter !== this.state.applyFilter && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
        if (prevState.searchKeyword !== this.state.searchKeyword && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
        if (prevProps.DeleteUserToggle !== this.props.DeleteUserToggle) {
            this.props.FetchAllUsers();
        }
    }

    handleClose() {
        this.setState(prevState => ({ ...prevState, showTogglePopup: false }));
    }

    handleEdit(user) {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword, } = this.state;
        let pagingInfo = {
            currentPage,
            startIndex,
            endIndex,
            totalItems,
            applyFilter,
            searchKeyword,
        }
        this.props.history.push({
            pathname: "/dashboard/user/add",
            state: { user, pagingInfo },
        });
    }

    showToggleWarning(user) {
        this.setState(prevState => ({ ...prevState, showTogglePopup: true, userIdForToggle: user.userId }));
    }

    handleToggleActiveState() {
        this.props.ToggleActiveState({ userId: this.state.userIdForToggle })
            .then(res => {
                this.handleClose();
                this.props.FetchAllUsers();
            });
    }

    handleUpdateClients() {
        this.props.UpdateClients();
        this.setState({ ...this.state, isClientUpdated: true });
    }

    handleAddNewUser() {
        this.props.history.push("/dashboard/user/add");
    }

    handleSearchKeywordChange(newKeyword) {
        let applyFilter = true;
        if (newKeyword === "") {
            applyFilter = false;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: newKeyword,
                applyFilter,
            }
        });
    }

    handleCancelSearch() {
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: '',
                applyFilter: false,
            }
        });
    }

    handleSearchRequest() {
        this.setState(prevState => {
            return {
                ...prevState,
                applyFilter: true,
            }
        });
    }

    handleKeyPress(event) {
        if (event.key == "Escape") {
            if (event.target.name == "searchBar") {
                this.handleCancelSearch();
            }

        }
    }

    restorePagingInfo() {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword } = this.props.location.state.pagingInfo;
        this.setState(prevState => {
            return {
                ...prevState,
                currentPage,
                startIndex,
                endIndex,
                totalItems,
                applyFilter,
                searchKeyword,
                toggleFlag: !this.state.toggleFlag,
            }
        });
    }

    setSortingParameters(feild) {
        let sortType;
        let type = "";
        let direction = '';
        let firstNameSorted = false;
        let middleNameSorted = false;
        let lastNameSorted = false;
        let countrySorted = false;
        let emailSorted = false;
        let {
            firstNameSortType,
            middleNameSortType,
            lastNameSortType,
            countrySortType,
            emailSortType,
            firstNameDirection,
            middleNameDirection,
            lastNameDirection,
            countryDirection,
            emailDirection,
        } = this.state;

        if (feild === "firstName") {
            sortType = this.state.firstNameSortType;

        } else if (feild === "middleName") {
            sortType = this.state.middleNameSortType;
        } else if (feild === "lastName") {
            sortType = this.state.lastNameSortType;
        } else if (feild === "country") {
            sortType = this.state.countrySortType;
        } else if (feild === "email") {
            sortType = this.state.emailSortType;
        }



        if (sortType === "default") {
            type = "ascending";
            direction = 'asc';
        } else if (sortType === "ascending") {
            type = "descending";
            direction = 'desc';
        } else if (sortType === "descending") {
            type = "default";
        }

        if (type === "ascending" || type === "descending") {
            if (feild === "firstName") {
                firstNameSorted = true;
                firstNameDirection = direction;
                firstNameSortType = type;
            } else if (feild === "middleName") {
                middleNameSorted = true;
                middleNameDirection = direction;
                middleNameSortType = type;
            } else if (feild === "lastName") {
                lastNameSorted = true;
                lastNameDirection = direction;
                lastNameSortType = type;
            } else if (feild === "country") {
                countrySorted = true;
                countryDirection = direction;
                countrySortType = type;
            } else if (feild === "email") {
                emailSorted = true;
                emailDirection = direction;
                emailSortType = type;
            }
        } else {
            if (feild === "firstName") {
                firstNameSorted = false;
                firstNameDirection = 'asc';
                firstNameSortType = "default";
            } else if (feild === "middleName") {
                middleNameSorted = false;
                middleNameDirection = "asc";
                middleNameSortType = "default";
            } else if (feild === "lastName") {
                lastNameSorted = false;
                lastNameDirection = "asc";
                lastNameSortType = "default";
            } else if (feild === "country") {
                countrySorted = false;
                countryDirection = "asc";
                countrySortType = "default";
            } else if (feild === "email") {
                emailSorted = false;
                emailDirection = "asc";
                emailSortType = "default";
            }
        }
        this.setState(prevState => {
            return {
                ...prevState,
                orderBy: feild,
                sortType: type,
                firstNameDirection,
                lastNameDirection,
                middleNameDirection,
                countryDirection,
                emailDirection,
                firstNameSorted,
                middleNameSorted,
                lastNameSorted,
                countrySorted,
                emailSorted,
                firstNameSortType,
                middleNameSortType,
                lastNameSortType,
                countrySortType,
                emailSortType,
            }
        });
    }

    applyFilter() {
        let clients = this.props.Clients;
        const searchKeyword = this.state.searchKeyword;
        const result = clients.filter(client => {
            if (client["firstName"] !== null) {
                let name = client["firstName"] + ' ' + (client["middleName"] !== null ? client["middleName"] : '') + ' ' + client["lastName"];
                if (name.toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["email"] !== null) {
                if (client["email"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["phone"] !== null) {
                if (client["phone"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if(client.address.country !== null){
                if (client.address.country.countryName !== null) {
                    if (client.address.country.countryName.toLowerCase().includes(searchKeyword.toLowerCase())) {
                        return true;
                    }
                }
                else {
                    return false;
                }
            }
            else{
                return false;
            }
        });
        return result;
    }

    sortClients(a, b) {
        const sortType = this.state.sortType;
        const orderBy = this.state.orderBy;
        let firstElement = "";
        let secondElement = "";
        if (orderBy === "country" && sortType !== "default") {
            firstElement = a.address.country?.countryName.toLowerCase();
            secondElement = b.address.country?.countryName.toLowerCase();
        } else if (orderBy !== "country" && sortType !== "default") {
            firstElement = a[orderBy] == null ? "" : a[orderBy].toLowerCase();
            secondElement = b[orderBy] == null ? "" : b[orderBy].toLowerCase();
        }

        if (sortType === "default") {
            return a;
        } else if (sortType === "ascending") {
            if (firstElement > secondElement) {
                return 1;
            } else if (firstElement <= secondElement) {
                return -1;
            }

        } else if (sortType === "descending") {
            if (firstElement >= secondElement) {
                return -1;
            } else if (firstElement < secondElement) {
                return 1;
            }
        }

    }

    setStartEndIndex(event, newPage = 0) {
        const { pageSize, applyFilter } = this.state;
        let totalItems;
        if (applyFilter) {
            totalItems = this.applyFilter();
            totalItems = totalItems.length;
        } else {
            totalItems = this.props.Clients.length;
        }
        const currentPage = newPage;
        let startIndex = (currentPage) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        this.setState({
            ...this.state,
            startIndex: startIndex,
            endIndex: endIndex,
            currentPage: newPage,
            totalItems
        });
    }

    GetTableBody() {
        const { applyFilter } = this.state;
        let clients;
        if (applyFilter) {
            clients = this.applyFilter();
        } else {

            clients = this.props.Clients;
        }
        let markup = [...clients].sort(this.sortClients).map((row) => (
            <TableRow key={row.userId}>
                <TableCell component="th" scope="row">{row.firstName}</TableCell>
                <TableCell align="left">{row.middleName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.address.country?.countryName}</TableCell>
                <TableCell align="left">
                    <Tooltip title={row.active ? "Deactivate" : "Activate"}>
                    <Button
                        color="secondary"
                        startIcon={row.active ? <CheckCircleOutlineOutlinedIcon className={"text-success"} /> : <CancelOutlinedIcon />}
                        onClick={() => this.showToggleWarning(row)}>
                    </Button>
                    </Tooltip>
                </TableCell>
                <TableCell align="left">
                    <Tooltip title="Edit">
                    <Button 
                        color="primary"
                        startIcon={<EditOutlinedIcon />} onClick={() => this.handleEdit(row)}>
                    </Button>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ));
        return markup.slice(this.state.startIndex, this.state.endIndex + 1);
    }
    render() {
        const tableBodyMarkup = this.GetTableBody();
        return (

            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <div className={"alert alert-success p-2 " + (this.state.isClientUpdated ? "d-block" : "d-none")} role="alert">
                        Clients Updated Successfully
                    </div>
                    <Typography component="h3" />
                    <div className="mb-15 text-right">
                        <Button variant="outlined" color="primary" size="small" startIcon={<SaveIcon />} onClick={this.handleAddNewUser} >Add a new user</Button>
                    </div>
                    <SearchBar
                        value={this.state.searchKeyword}
                        cancelOnEscape={true}
                        onChange={(newKeyword) => this.handleSearchKeywordChange(newKeyword)}
                        onRequestSearch={() => this.handleSearchRequest()}
                        onCancelSearch={() => this.handleCancelSearch()}
                        inputRef={this.searchBarRef}
                        name="searchBar"
                        onKeyDown={this.handleKeyPress}
                    />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.firstNameSorted}
                                            direction={this.state.firstNameDirection}
                                            onClick={() => this.setSortingParameters("firstName")}
                                        >
                                            First Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.middleNameSorted}
                                            direction={this.state.middleNameDirection}
                                            onClick={() => this.setSortingParameters("middleName")}
                                        >
                                            Middle Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.lastNameSorted}
                                            direction={this.state.lastNameDirection}
                                            onClick={() => this.setSortingParameters("lastName")}
                                        >
                                            Last Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.emailSorted}
                                            direction={this.state.emailDirection}
                                            onClick={() => this.setSortingParameters("email")}
                                        >
                                            Email
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="left">Phone</TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.countrySorted}
                                            direction={this.state.countryDirection}
                                            onClick={() => this.setSortingParameters("country")}
                                        >
                                            Country
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableBodyMarkup}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={this.state.totalItems}
                        rowsPerPage={this.state.pageSize}
                        page={this.state.currentPage}
                        onChangePage={this.setStartEndIndex}
                    />
                    <Dialog
                        open={this.state.showTogglePopup}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title"></DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            Are you sure you want to change user status?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleToggleActiveState} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>

            </React.Fragment>
        )

    }

}

function mapStateToProps(store) {
    return {
        Clients: store.Admin.users,
        DeleteUserToggle: store.Admin.deleteUserToggle
    }
}

const mapActionsToProps = {
    FetchAllUsers,
    ToggleActiveState
}

export default connect(mapStateToProps, mapActionsToProps)(AdminUsersDetails)