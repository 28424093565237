import {
    LOGOUT_ADMIN,
    FETCH_ALL_USERS,
    ADD_NEW_USER,
    ADD_NEW_USER_ERROR,
    EDIT_USER,
    DELETE_USER,
    GET_ALL_COUNTRIES,
    TOGGLE_ACTIVE_STATE,
    GET_PRE_MATCH_ODDS_PREFERENCE,
    SET_PRE_MATCH_ODDS_PREFERENCE,
    GET_UPCOMING_EVENTS,
    RESET_PASSWORD,
} from "./ActionTypes";
import Axios from "axios";

export const FetchAllUsers = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/BetSmartUsers/get");
        dispatch({ type: FETCH_ALL_USERS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        
    }
}

export const AddNewUser = (newUser, history) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/BetSmartUsers/add", newUser);
        dispatch({ type: ADD_NEW_USER, payload: res.data.Result });
        history.push("/dashboard/users");
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        if (error.response.data.StatusCode == 400) {
            dispatch({ type: ADD_NEW_USER_ERROR, payload: error.response.data.Message })
        }
        
    }
}

export const EditUser = (userDetails, history) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/BetSmartUsers/update", userDetails);
        dispatch({ type: EDIT_USER, payload: res.data.Result });
        history.push("/dashboard/users");
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        if (error.response.data.StatusCode == 400) {
            dispatch({ type: ADD_NEW_USER_ERROR, payload: error.response.data.Message })
        }
        
    }

}

export const DeleteUser = (userDetails, history) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/BetSmartUsers/delete", userDetails);
        dispatch({ type: DELETE_USER, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        
    }

}

export const GetAllCountries = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/BetSmartCountries/get");
        dispatch({ type: GET_ALL_COUNTRIES, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        
    }

}

export const ToggleActiveState = (userDetails) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/BetSmartUsers/update/active", userDetails);
        dispatch({ type: TOGGLE_ACTIVE_STATE, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        
    }
}

export const GetPreMatchOddsPreference = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/Auth/prematchodds");
        dispatch({ type: GET_PRE_MATCH_ODDS_PREFERENCE, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        
    }
}

export const SetPreMatchOddsPreference = (odds) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/Auth/prematchodds", odds);
        dispatch({ type: SET_PRE_MATCH_ODDS_PREFERENCE, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        
    }
}

export const GetUpcomingEvents = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/upcomingevent/get");
        dispatch({ type: GET_UPCOMING_EVENTS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
    }
}

export const ResetPassword = (email, token) => async (dispatch) => {
    try {
        const res = await Axios.get("/api/mobile/user/password/reset/" + email + "/" + token);
        dispatch({ type: RESET_PASSWORD, payload: true });
    } catch (error) {
        dispatch({ type: RESET_PASSWORD, payload: false });
    }
}
