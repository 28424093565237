import React, { useState} from 'react';  
import {Link} from 'react-router-dom'
import LeftMenu from './leftMenu'; 
import {nav} from '../../Data/nav';
import {RadioButtonUnchecked,ExpandMore} from '@material-ui/icons'; 
 
export default function Menu(props) {  
 
    const [values, setValues] = React.useState({
        navigation: nav, 
      });

    const [hovered, setHovered] = React.useState(false); 
    const [menuItemActive, setMenuItemActive] = useState({dashboard:true, client: false, settings: false, invoice: false, upcomingEvents: false});


    const handleActiveState = (name) => {
        if (name == "dashboard") {
            setMenuItemActive({ dashboard: true, client: false, settings: false, invoice: false, upcomingEvents: false });
        } else if (name == "client") {
            setMenuItemActive({ dashboard: false, client: true, settings: false, invoice: false, upcomingEvents: false });
        } else if (name == "settings") {
            setMenuItemActive({ dashboard: false, client: false, settings: true, invoice: false, upcomingEvents: false });
        } else if (name == "invoice") {
            setMenuItemActive({ dashboard: false, client: false, settings: false, invoice: true, upcomingEvents: false });
        } else if (name == "upcomingEvents") {
            setMenuItemActive({ dashboard: false, client: false, settings: false, invoice: false, upcomingEvents: true });
        }
    }

    const setMenuVisibility=(index)=>
    { 
        let list =  values.navigation;
        //list.map(a=>a.isVisible=false);
        list[index].isVisible=!list[index].isVisible;
        setValues({ ...values, navigation: list });    
    }  
  
    const setActive=(indexUpper, itemIndex)=>
    {
        let list =  values.navigation;
        list.map((item,index)=>{
            item.menuItems.map(a=>a.isActive=false); 
        });  
        list[indexUpper].menuItems[itemIndex].isActive=true;
        setValues({ ...values, navigation: list });    
    }

    return (
        <div onMouseEnter={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}} className={"leftbar"+ (props.toggleProp?"":"menuToggle")}>   
            <div className="dummy1"  ><div className='dummy2'  ></div></div>   
        
            <div className="navigationbar"> 
 

                <div className={"vertical-menu-detail " + (props.toggleProp?"":"menuToggle")  } > 
                        <div className="tab-content"> 
                                <div  className={"fade show "  }>
                                    <ul className="vertical-menu in"> 
                                        {/* { 
                                            values.navigation.map((nav1,index)=> (
                                                <li key={index}  > 
                                                        {nav1.url!=null?<Link to={nav1.url}> <img src={nav1.icon} />{nav1.name} </Link>:
                                                        <React.Fragment>
                                                        <a onClick={()=>setMenuVisibility(index)} className='ms-fontSize-20'> <img src={nav1.icon} /><i><ExpandMore/></i>{nav1.name}  </a>
                                                            <ul className={"lowMenu " +(nav1.isVisible && hovered?"show":"hide")}>
                                                            {nav1.menuItems.map((item,index1)=>
                                                                <li key={index1} className={item.isActive?"active":""}><Link to={item.url} onClick={()=>setActive(index,index1)} ><i><RadioButtonUnchecked/></i> {item.name}</Link></li>
                                                            )
                                                            }</ul></React.Fragment>
                                                        }
                                                </li>
                                            ))
                                            
                                            
                                       
                                       } */}

                                <li className={menuItemActive.dashboard ? "active" : ""} onClick={() => handleActiveState("dashboard")}><Link to="/dashboard/info"><i className="fas fa-home"></i> <span>Dashboard</span></Link></li>

                                <li className={menuItemActive.client ? "active" : ""} onClick={() => handleActiveState("client")} > <Link to="/dashboard/users"> <i className="fas fa-users"></i> <span>Client</span></Link></li>

                                <li className={menuItemActive.settings ? "active" : ""} onClick={() => handleActiveState("settings")} > <Link to="/dashboard/settings"> <i className="fas fa-cogs"></i> <span>Settings</span></Link></li>

                                <li className={menuItemActive.upcomingEvents ? "active" : ""} onClick={() => handleActiveState("upcomingEvents")} > <Link to="/dashboard/upcomingEvents"> <i className="far fa-calendar"></i> <span>Upcoming Events</span></Link></li>

                                       {/*<li className={menuItemActive.invoice ? "active" : ""} onClick={() => handleActiveState("invoice")} > <Link to="/dashboard/invoice"><i className="fas fa-file-invoice"></i> <span>Invoice</span></Link></li>*/}

                                       {/*<li className={menuItemActive.product ? "active" : ""} onClick={() => handleActiveState("product")} > <Link to="/dashboard/products"><i className="fas fa-th"></i> <span>Product</span></Link></li>*/}

                                                
                                                {/* {nav1.menuItems.map((item,index1)=>
                                                    <li key={index1} className={item.isActive?"active":""}><Link to={item.url} onClick={()=>setActive(index,index1)} ><img src={item.icon} />{item.name}</Link></li>
                                                )}  */}
                                    </ul>
                                </div>   
                        </div>
                </div> 
            </div> 
        </div>
    );
}