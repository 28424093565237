export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const ADMIN_LOGIN_ERROR = "ADMIN_LOGIN_ERROR";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_ERROR = "ADD_NEW_USER_ERROR";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const TOGGLE_ACTIVE_STATE = "TOGGLE_ACTIVE_STATE";
export const GET_PRE_MATCH_ODDS_PREFERENCE = 'GET_PRE_MATCH_ODDS_PREFERENCE';
export const SET_PRE_MATCH_ODDS_PREFERENCE = 'SET_PRE_MATCH_ODDS_PREFERENCE';
export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';
export const RESET_PASSWORD = "RESET_PASSWORD";