import Axios from 'axios';

let AxiosInstance = Axios.create();

export function GetAxiosInstance(user = "default") {
    switch (user) {
        case "Merchant":
            AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`
            return AxiosInstance;
        default:
            return AxiosInstance;
    }
}