import React from 'react'
import useGloablState from './useGlobalState'
import context from './context'

const GlobalStateProvider =({children})=>{

    return (
        <context.Provider value={useGloablState()}>{children}</context.Provider>
    );
};
export default GlobalStateProvider;
