export const nav =  [
  { 
    icon: require("../assets/logo/ui-kits.svg"), 
    tagImage : require('../assets/logo/receipt.png'),
    name: "Dashboard",
    url:"/dashboard/info",
    isVisible : true,
    menuItems: []
  },
    { 

      icon: require("../assets/logo/pages.svg"), 
      tagImage : require('../assets/logo/receipt.png'),
      name: "Invoice",
      isVisible : false,
      menuItems:[ 
                {
                  icon:require("../assets/logo/basic.svg"),
                  url:"/dashboard/invoice",
                  name:"Invoice",
                  isActive:false,
                },
                //{
                //  icon:require("../assets/logo/crm.svg"),
                //  url:"/Clients",
                //  name:"Clients",
                //  isActive:false,
                //},
                //{
                //    icon:require("../assets/logo/settings.svg"),
                //    url:"/Setting",
                //    name:"Setting",
                //    isActive:false,
                //  },
                //  {
                //    icon:require("../assets/logo/advanced.svg"),
                //    url:"/Support",
                //    name:"Support",
                //    isActive:false,
                //  }
                ] 
    },
    {   icon: require("../assets/logo/crm.svg"), 
        tagImage : require('../assets/logo/reception.png'),
        name: "Clients", 
        isVisible : false,
        menuItems: [
            {
                icon: require("../assets/logo/dashboard.svg"),
                    url:"/dashboard/clients",
                    name:"Clients",
                    isActive:false,
                },
            //    {
            //        icon:require("../assets/logo/basic.svg"),
            //        url:"/UserAction",
            //        name:"UserAction",
            //        isActive:false,
            //}
        ]  
    },
    {
        icon: require("../assets/logo/crm.svg"),
        tagImage: require('../assets/logo/reception.png'),
        name: "Products",
        isVisible: false,
        menuItems: [
            {
                icon: require("../assets/logo/ecommerce.svg"),
                url: "/dashboard/products",
                name: "Products",
                isActive: false,
            },]
     },
//    {   icon: require("../assets/logo/ui-kits.svg"), 
//        tagImage : require('../assets/logo/passed.png'),
//        name: "Teams",
//        isVisible : false,
//        menuItems:[{icon:require("../assets/logo/dashboard.svg"),
//                    url:"/TeamList",
//                    name:"TeamList",
//                    isActive:false,
//                    },
//                    {
//                        icon:require("../assets/logo/basic.svg"),
//                        url:"/TeamAction",
//                        name:"TeamAction",
//                        isActive:false,
//                    }
//                ]  
//},

//{icon: require("../assets/logo/settings.svg"), 
//tagImage : require('../assets/logo/passed.png'),
//name: "Setting",
//isVisible : false,
//menuItems:[{icon:require("../assets/logo/authentication.svg"),
//            url:"/Account",
//            name:"Account",
//            isActive:false,
//            },
//            {
//                icon:require("../assets/logo/basic.svg"),
//                url:"/Payments",
//                name:"Payments",
//                isActive:false,
//            }
//        ]  
//},


]; 