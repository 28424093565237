import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import GlobalStateProvider from './store/GlobalStateProvider';
import AdminLogin from "./Routes/AdminLogin/AdminLogin";
import AdminDashboard from "./Routes/AdminDashboard/AdminDashboard";
import AdminProtectedRoutes from "./components/AdminProtectedRoutes/AdminProtectedRoutes";
import ForgetPassword from "./Routes/ForgetPassword/ForgetPassword";

export default function App() {
    //class App extends Component { 

    const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
    //const Login = React.lazy(() => import('./Views/Pages/Login/Login')); 
    const Register = React.lazy(() => import('./Views/Pages/Register/Register'));
    const Home = React.lazy(() => import('./Views/Home/index'));


    return (

        <GlobalStateProvider>
            <BrowserRouter>
                <React.Suspense fallback={<LinearProgress className="LinearProgress"></LinearProgress>}>
                    <Switch>
                        <AdminProtectedRoutes path="/dashboard" component={AdminDashboard} />
                        {/* <AdminProtectedRoutes path="/dashboard/upcomingEvents" component={AdminUpcomingEvents} /> */}

                        <Route exact path="/user/password/reset/:email/:forgetPasswordToken" component={ForgetPassword} />
                        <Route path="/" component={AdminLogin} />
              </Switch>
                </React.Suspense>
            </BrowserRouter>
        </GlobalStateProvider>

    );
}

// export default App;
