import React, { createRef } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Tooltip from '@material-ui/core/Tooltip';

import { AddNewUser, EditUser, GetAllCountries } from "../../Redux/Actions/AdminDashboardActions";

class AdminAddNewUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            FirstName: "",
            MiddleName: "",
            LastName: "",
            EmailAddress: "",
            PhoneNumber: "",
            City: "",
            Country: "",
            Active: true,
            errors: {
                FirstName: "",
                MiddleName: "",
                LastName: "",
                EmailAddress: "",
                PhoneNumber: "",
            },
            showFirstNameError: false,
            showMiddleNameError: false,
            showLastNameError: false,
            showEmailAddressError: false,
            showPhoneNumberError: false,
            showCityError: false,
            showCountryError: false,
        };
        this.FirstNameRef = createRef();
        this.MiddleNameRef = createRef();
        this.LastNameRef = createRef();
        this.EmailAddressRef = createRef();
        this.PhoneNumberRef = createRef();
        this.CityRef = createRef();
        this.CountryRef = createRef();
        this.SaveButtonRef = createRef();
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleServerSideErrors = this.handleServerSideErrors.bind(this);
        this.doValidation = this.doValidation.bind(this);
        this.loadValuesInForm = this.loadValuesInForm.bind(this);
        this.setCountry = this.setCountry.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            this.loadValuesInForm();
        }
        this.FirstNameRef.current.focus();
        this.props.GetAllCountries()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.serverErrorsToggle !== this.props.serverErrorsToggle) {
            this.handleServerSideErrors();
        }
    }

    handleCancel() {
        this.props.history.push('/dashboard/users');
    }

    handleSave() {
        let { FirstName,
            MiddleName,
            LastName,
            EmailAddress,
            PhoneNumber,
            City,
            Country,
            Active, } = this.state

        let newUser = {
            FirstName,
            MiddleName,
            LastName,
            Email: EmailAddress,
            Phone: PhoneNumber,
            City,
            CountryId: Country.countryId ? Country.countryId : "",
            Active,
        }

        const result = this.doValidation();
        const { valid } = result;
        if (valid) {
            if (this.props.location.state == undefined) {
                this.props.AddNewUser(newUser, this.props.history);
            }
            if (this.props.location.state !== undefined) {
                newUser.UserId = this.props.location.state.user.userId;
                this.props.EditUser(newUser, this.props.history);
            }
        } else {
            const {
                errors,
                showFirstNameError,
                showMiddleNameError,
                showLastNameError,
                showEmailAddressError,
                showPhoneNumberError,
                showCityError,
                showCountryError } = result;
            this.setState(prevState => ({
                ...prevState,
                errors,
                showFirstNameError,
                showMiddleNameError,
                showLastNameError,
                showEmailAddressError,
                showPhoneNumberError,
                showCityError,
                showCountryError,
            }));
        }
    }

    handleServerSideErrors() {
        const serverSideErrors = this.props.errors;
        if (serverSideErrors.EmailAddress != "") {
            this.setState(prevState => ({
                ...prevState,
                showEmailAddressError: true,
                showFirstNameError: false,
                showMiddleNameError: false,
                showLastNameError: false,
                showPhoneNumberError: false,
                errors: {
                    EmailAddress: serverSideErrors.EmailAddress
                }
            }))
        }
    }

    setCountry(event, value) {
        if (value === null) {
            this.setState({ ...this.state, Country: '' });
        }
        else {
            this.setState({ ...this.state, Country: value });
        }
    }

    handleChange(event) {
        if (event.target.id !== "Country") {
            if (event.target.id === "Active") {
                this.setState({ ...this.state, [event.target.id]: event.target.checked })
            } else {
                this.setState({ ...this.state, [event.target.id]: event.target.value });
            }
        }

    }

    handleKeyPress(event) {
        if (event.key == "Enter") {
            if (event.target.id == "FirstName") {
                this.MiddleNameRef.current.focus();
            } else if (event.target.id == "MiddleName") {
                this.LastNameRef.current.focus();
            } else if (event.target.id == "LastName") {
                this.EmailAddressRef.current.focus();
            } else if (event.target.id == "EmailAddress") {
                this.PhoneNumberRef.current.focus();
            } else if (event.target.id == "PhoneNumber") {
                this.CityRef.current.focus();
            } else if (event.target.id == "City") {
                this.CountryRef.current.focus();
            } else if (event.target.id == "Country") {
                this.SaveButtonRef.current.click();
            }
        }
    }

    doValidation() {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errors = {};
        let valid = false;
        let showFirstNameError = false;
        let showMiddleNameError = false;
        let showLastNameError = false;
        let showEmailAddressError = false;
        let showPhoneNumberError = false;
        let showCityError = false;
        let showCountryError = false;

        if (this.state.FirstName.trim() == '') {
            errors.FirstName = "Please enter a first Name";
            showFirstNameError = true;
        }
        else if (this.state.FirstName.length > 20) {
            errors.FirstName = "Please enter a name shorter then 20 character";
            showFirstNameError = true;
        }
        if (this.state.MiddleName.length > 20) {
            errors.MiddleName = "Please enter a middle name shorter then 20 character"
            showMiddleNameError = true;
        }
        if (this.state.EmailAddress.trim() == '') {
            errors.EmailAddress = "Please enter a email address";
            showEmailAddressError = true;
        }
        else if (!emailRegex.test(this.state.EmailAddress.toLowerCase())) {
            errors.EmailAddress = "Please enter a valid email address";
            showEmailAddressError = true;
        }
        if(this.state.PhoneNumber == ''){
            errors.PhoneNumber = "Please enter a phone number";
            showPhoneNumberError = true;
        }
        else if (this.state.PhoneNumber.length > 15 || this.state.PhoneNumber.length < 7) {
            errors.PhoneNumber = "Please enter a phone number with more than 7 digits and less than 15 digit";
            showPhoneNumberError = true;
        }
        if (this.state.City == '') {
            errors.City = "Please enter a city";
            showCityError = true;
        }
        else if (this.state.City.length < 3 || this.state.City.length > 30) {
            errors.City = "Please enter a city name of more than 2 character and less than 30 character";
            showCityError = true;
        }
        if (this.state.Country == '') {
            errors.Country = "Please select a country";
            showCountryError = true;
        }

        let errorTypes = Object.keys(errors);
        if (errorTypes.length == 0) {
            valid = true;
        }

        const result = {
            errors,
            valid,
            showFirstNameError,
            showMiddleNameError,
            showLastNameError,
            showEmailAddressError,
            showPhoneNumberError,
            showCityError,
            showCountryError,
        };

        return result;
    }


    loadValuesInForm() {
        const user = this.props.location.state.user;
        this.setState(prevState => ({
            ...prevState,
            FirstName: user.firstName,
            MiddleName: user.middleName == null ? "" : user.middleName ,
            LastName: user.lastName == null ? "" : user.lastName,
            EmailAddress: user.email,
            PhoneNumber: user.phone == null ? "" : user.phone,
            City: user.address.city == null ? "" : user.address.city,
            Country: user.address.country == null ? "" : user.address.country ,
            Active: user.active,
        }));
    }



    render() {
        const isEditing = this.props.location.state == undefined ? false : true;
        return (
            <Paper className="p-3">
                {/*start of container grid*/}
                <Grid container spacing={2} alignContent='center'>
                    {/*Start of first name*/}
                    <Grid item xs={12} sm={4} md={4} >
                        <TextField
                            id='FirstName'
                            label="First Name"
                            placeholder="First Name"
                            inputRef={this.FirstNameRef}
                            type="text"
                            value={this.state.FirstName}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={this.state.showFirstNameError}
                        />
                        <FormHelperText id="FirstName" className="text-danger">
                            {this.state.errors.FirstName}
                        </FormHelperText>
                    </Grid>
                    {/*End of first name*/}

                    {/*Start of middle name*/}
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id='MiddleName'
                            label="Middle Name"
                            placeholder="Middle Name"
                            inputRef={this.MiddleNameRef}
                            type="text"
                            value={this.state.MiddleName}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={this.state.showMiddleNameError}
                        />
                        <FormHelperText id="MiddleName" className="text-danger">
                            {this.state.errors.MiddleName}
                        </FormHelperText>
                    </Grid>
                    {/*End of middle name*/}

                    {/*Start of last name*/}
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            id='LastName'
                            label="Last Name"
                            placeholder="Last Name"
                            inputRef={this.LastNameRef}
                            type="text"
                            value={this.state.LastName}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={this.state.showLastNameError}
                        />
                        <FormHelperText id="LastName" className="text-danger">
                            {this.state.errors.LastName}
                        </FormHelperText>
                    </Grid>
                    {/*End of last name*/}

                    {/*Start of email address*/}
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id='EmailAddress'
                            label="Email Address"
                            placeholder="email@example.com"
                            inputRef={this.EmailAddressRef}
                            type="email"
                            value={this.state.EmailAddress}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={this.state.showEmailAddressError}
                        />
                        <FormHelperText id="EmailAddress" className="text-danger">
                            {this.state.errors.EmailAddress}
                        </FormHelperText>
                    </Grid>
                    {/*End of email address*/}

                    {/*Start of phone number*/}
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id='PhoneNumber'
                            label="Phone Number"
                            placeholder="Phone Number"
                            inputRef={this.PhoneNumberRef}
                            type="number"
                            value={this.state.PhoneNumber}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={this.state.showPhoneNumberError}
                        />
                        <FormHelperText id="PhoneNumber" className="text-danger">
                            {this.state.errors.PhoneNumber}
                        </FormHelperText>
                    </Grid>
                    {/*End of phone number*/}

                    {/*Start of City*/}
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id='City'
                            label="City"
                            placeholder="City"
                            inputRef={this.CityRef}
                            type="text"
                            value={this.state.City}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={this.state.showCityError}
                        />
                        <FormHelperText id="City" className="text-danger">
                            {this.state.errors.City}
                        </FormHelperText>
                    </Grid>
                    {/*End of City*/}

                    {/*Start of Country*/}
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            id='Country'
                            options={this.props.countries}
                            getOptionLabel={option => option.countryName ? option.countryName : ""}
                            value={this.state.Country}
                            onChange={(event, value) => this.setCountry(event, value)}
                            renderInput={(params) => <TextField{...params}
                                error={this.state.showCountryError}
                                inputRef={this.CountryRef}
                                onKeyPress={this.handleKeyPress}
                                label="Country"
                                onChange={this.handleChange}
                                variant="outlined"
                                margin="dense" />
                            }
                            fullWidth
                            autoSelect
                            autoHighlight

                        />
                        <FormHelperText id="AddressLine2" className="text-danger">
                            {this.state.errors.Country}
                        </FormHelperText>
                    </Grid>
                    {/*End of Country*/}

                    {/* Start of Active switch */}
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="Active"
                                    checked={this.state.Active}
                                    onChange={this.handleChange}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                    {/*End of Active switch*/}

                    <Grid item xs={12} sm={6} md={6}>
                        <Tooltip title={this.props.location.state !== undefined ? "Edit" : "Save"}>
                        <Button
                            variant="contained"
                            color='primary'
                            className={"m-2 no-margin"}
                            buttonRef={this.SaveButtonRef}
                            onClick={this.handleSave}
                            startIcon={<SaveIcon fontSize="large"/>}
                        >
                        </Button>
                        </Tooltip>

                        <Tooltip title="Back">
                        <Button
                            variant="contained"
                            color='secondary'
                            className={"no-margin"}
                            onClick={this.handleCancel}
                            startIcon={<BackspaceIcon/>}>
                        </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                {/*End of container grid*/}
            </Paper>
        )
    }
}

function mapStateToProps(store) {
    return {
        errors: store.Admin.userErrors,
        serverErrorsToggle: store.Admin.serverErrorsToggle,
        countries: store.Admin.countries,
    }
}

const mapActionToProps = {
    AddNewUser,
    EditUser,
    GetAllCountries,
}


export default connect(mapStateToProps, mapActionToProps)(AdminAddNewUser)