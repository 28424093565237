import { LOGIN_ADMIN,LOGOUT_ADMIN, ADMIN_LOGIN_ERROR } from './ActionTypes';
import { GetAxiosInstance } from "../Axios";

const Axios = GetAxiosInstance();
export const LoginAdmin = (loginDetails) => async(dispatch) => {
    try {
       
        const res = await Axios.post("/api/Auth/login", loginDetails);
        localStorage.setItem('Auth_Token', res.data.Result.auth_token);
        dispatch({ type: LOGIN_ADMIN, payload: res.data.Result.auth_token });
        
    } catch (error) {
        dispatch({ type: ADMIN_LOGIN_ERROR })
        console.log(error);
    }
}

export const IsLoggedIn = () => (dispatch) => {
    const token = localStorage.getItem('Auth_Token');
    if (token) {
        dispatch({ type: LOGIN_ADMIN, payload: token });
    }
}

export const LogoutAdmin = () => (dispatch) => {
    console.log("function executed")
    localStorage.removeItem('Auth_Token');
    dispatch({type: LOGOUT_ADMIN});
}