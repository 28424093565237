import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import Menu from "../../containers/DefaultLayout/Menu";
import RightBar from "../../containers/DefaultLayout/rightBar";
import Header from "../../containers/DefaultLayout/Header";
import Footer from "../../containers/DefaultLayout/Footer";
import AdminUsersDetail from "../AdminUsersDetail/AdminUsersDetail";
import AdminAddNewUser from "../AdminAddNewUser/AdminAddNewUser";
import AdminInfo from "../AdminInfo/AdminInfo";
import Container from '@material-ui/core/Container';
import AdminSettings from '../AdminSettings/AdminSettings';
import AdminUpcomingEvents from '../AdminUpcomingEvents/AdminUpcomingEvents';

class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleState: true
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    componentDidMount() {
     
    }

    handleToggleMenu() {
        this.setSate({ ...this.state, toggleState: !this.state.toggleState })
    }

    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Header />
                <div className="row m-0">
                    <div className="col-lg-3 col-xl-2 p-0"><Menu toggleProp={this.state.toggleState} /></div>
                    <div className="col-lg-9 col-md-12 col-sm-12 col-xl-10 pr-0 main-wraper">
                        <Container>
                            < Switch >
                                <Route path={`${match.url}/info`} component={AdminInfo} />
                                <Route path={`${match.url}/settings`} component={AdminSettings} />
                                <Route path={`${match.url}/users`} component={AdminUsersDetail} />
                                <Route path={`${match.url}/upcomingEvents`} component={AdminUpcomingEvents} />
                                <Route path={`${match.url}/user/add`} component={AdminAddNewUser} />
                            </Switch >
                        </Container>
                    </div>
                </div>
                {/* <Footer /> */}
            </React.Fragment>
        )

    }
}
function mapStateToProps(store) {
    return {
        Clients: store.Admin.clients
    }
}

const mapActionsToProps = {
  
}

export default connect(mapStateToProps, mapActionsToProps)(AdminDashboard);