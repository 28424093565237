import {
    FETCH_ALL_USERS,
    ADD_NEW_USER,
    ADD_NEW_USER_ERROR,
    DELETE_USER,
    GET_ALL_COUNTRIES,
    TOGGLE_ACTIVE_STATE,
    GET_PRE_MATCH_ODDS_PREFERENCE,
    SET_PRE_MATCH_ODDS_PREFERENCE,
    GET_UPCOMING_EVENTS,
    RESET_PASSWORD,
} from "../Actions/ActionTypes"

const InitialState = {
    clients: [],
    products: [],
    invoices: [],
    transaction: {
        paymentDate: '',
        transactionId: ''
    },
    dashboard: {
        numberOfClients: '',
        numberOfInvoices: '',
        numberOfPaidInvoices: '',
        numberOfUnpadiInvoices: '',
    },
    users: [],
    userErrors: {
        "EmailAddress": "",
    },
    serverErrorsToggle: false,
    deleteUserToggle: false,
    countries: [],
    settings: {
        odds: 0,
    },
    upcomingEvents: [],
    resetPasswordStatus: false,
    resetPasswordRequest: false,
};

export default function AdminReducer(state = InitialState, action) {

    switch (action.type) {
        case FETCH_ALL_USERS:
            return { ...state, users: [...action.payload] }
        case ADD_NEW_USER:
            return state;
        case ADD_NEW_USER_ERROR:
            return {
                ...state, userErrors: { "EmailAddress": action.payload }, serverErrorsToggle: !state.serverErrorsToggle,
            };
        case DELETE_USER:
            return { ...state, deleteUserToggle: !state.deleteUserToggle };
        case GET_ALL_COUNTRIES:
            return { ...state, countries: [...action.payload] };
        case TOGGLE_ACTIVE_STATE:
            return state;
        case SET_PRE_MATCH_ODDS_PREFERENCE:
            return { ...state, settings: { ...state.settings, odds: action.payload } };
        case GET_PRE_MATCH_ODDS_PREFERENCE:
            return { ...state, settings: { ...state.settings, odds: action.payload } };
        case GET_UPCOMING_EVENTS:
            return { ...state, upcomingEvents: [...action.payload] };
        case RESET_PASSWORD:
            return { ...state, resetPasswordStatus: action.payload, resetPasswordRequest: true };
        default:
            return state;
    }
}



